#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--bs-text-info);
  position: fixed;
  z-index: 1031999999999999999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

/* Fancy blur effect */

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--bs-text-info), 0 0 5px var(--bs-text-info);
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031999999999999999999;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: var(--bs-text-info);
  border-left-color: var(--bs-text-info);
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.splash-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.splash-screen .loading-dot {
  font-size: 100px;
  margin-top: -80px;
  position: relative;
  animation: ease-in-out infinite alternate;
  animation-name: run;
  animation-duration: 1.2s;
}

@keyframes run {
  0% {
    left: -90px;
    color: #eee;
  }
  50% {
    color: #666;
  }
  100% {
    left: 90px;
    color: #eee;
  }
}
